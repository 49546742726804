<template>
  <div :class="$style.relation">
    <div :class="$style.content">
      <p :class="$style.title">
        内容关联
        <a-popover placement="bottom" title="说明">
          <template slot="content">
            <p>此处关联的文化点内容也可以通过文化点主页编辑器进行操作，两者的关联数据保持一致。</p>
          </template>
          <i :class="$style.question" class="ri-question-line" />
        </a-popover>
      </p>
      <div class="layout">
        <div class="label" :class="$style.stitle">选择内容</div>
        <div class="value pt-4">
          <BjButton class="btn-default" @click="onAddContent()"><i class="ri-bill-line left" />选择内容</BjButton>
          <BjButton class="btn-default ml-10" @click="onAddSort()"
            ><i class="ri-settings-line left" />排序配置</BjButton
          >
          <div :class="$style.contentBox">
            <div
              v-for="(item, index) in contentData.slice(0, show ? contentData.length : 10)"
              :key="index"
              :class="$style.contentItem"
            >
              <img :class="$style.img" :src="item.cover.cover" />
              <div :class="$style.text">
                <p :class="$style.ttitle" class="ellipsis-width">{{ item.title }}</p>
                <p class="tip ellipsis-width" :title="desc(item)">
                  类型:
                  <span :class="$style.blank">{{ item.content_type | typeName }}</span>
                  分组:
                  <span :class="$style.blank">{{ item.group_name }}</span>
                  作者:
                  <span :class="$style.blank">{{ item.author.real_name || '-' }}</span>
                </p>
              </div>
              <div :class="$style.contentBtn">
                <i class="ri-eye-line primary" @click="onPreview(item)" />
                <i class="ri-delete-bin-7-line" @click="onDeleteContent(index)" />
              </div>
            </div>
            <div v-if="contentData.length > 10" :class="$style.more">
              <span :class="$style.moreLeft">内容数量：{{ contentData.length }}条</span>
              <span :class="$style.moreRight" @click="onShow()">
                {{ !show ? '展开全部' : '显示部分' }}
                <i v-if="!show" class="ri-arrow-down-s-line" />
                <i v-else class="ri-arrow-up-s-line" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.chart">
      <p :class="$style.title">关系图谱</p>
      <div class="layout">
        <div class="label" :class="$style.stitle">选择关系</div>
        <div class="value pt-4">
          <BjButton class="btn-default mb-10" @click="onAddRelation()"
            ><i class="ri-links-line left" />添加关系</BjButton
          >
          <a-row :class="$style.relationBox" :gutter="[20, 20]">
            <a-col
              v-for="(item, index) in relationData.slice(0, showRelation ? relationData.length : 10)"
              :key="index"
              span="12"
            >
              <div :class="$style.box">
                <div :class="$style.left">
                  <div :class="$style.text">
                    <p class="ellipsis" :class="$style.ttitle" :title="item.main_poc.name">{{ item.main_poc.name }}</p>
                    <p class="ellipsis tip" :title="item.main_poc.group_name">{{ item.main_poc.group_name }}</p>
                  </div>
                  <img :src="item.main_poc.cover.cover" alt="" />
                </div>
                <div :class="$style.right">
                  <img :src="item.rel_poc.cover.cover" alt="" />
                  <div :class="$style.text">
                    <p class="ellipsis" :class="$style.ttitle" :title="item.rel_poc.name">{{ item.rel_poc.name }}</p>
                    <p class="ellipsis tip" :title="item.rel_poc.group_name">{{ item.rel_poc.group_name }}</p>
                  </div>
                </div>
                <div :class="$style.name">
                  <i class="ri-link-m" />
                  {{ item.poc_relation_name }}
                </div>
                <div :class="$style.btn">
                  <i class="ri-edit-2-line" @click="onEditRelation(item)" />
                  <i class="ri-delete-bin-7-line" @click="onDeleteRelation(item, index)" />
                </div>
              </div>
            </a-col>
          </a-row>
          <div v-if="relationData.length > 10" :class="$style.more">
            <span :class="$style.moreLeft">内容数量：{{ relationData.length }}条</span>
            <span :class="$style.moreRight" @click="onShowRelation()">
              {{ !showRelation ? '展开全部' : '显示部分' }}
              <i v-if="!showRelation" class="ri-arrow-down-s-line" />
              <i v-else class="ri-arrow-up-s-line" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <bj-modal :visible="visible.sort" title="排序配置" :body-style="{ height: '400px' }" @cancel="cancel" @ok="cancel">
      <a-row>
        <a-col span="6" :class="$style.stitle">
          <span class="required">*</span>
          排序方式
        </a-col>
        <a-col span="18">
          <a-radio-group v-model="content_sort_type">
            <a-radio :style="radioStyle" :value="0"> 用户位置与内容距离（近-远）</a-radio>
            <a-radio :style="radioStyle" :value="1"> 内容阅读数量（多-少） </a-radio>
            <a-radio :style="radioStyle" :value="2"> 内容发布时间（近-远） </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
    </bj-modal>
    <chooseContent :visible.sync="visible.choose" :multiple="true" :select="contentData" @change="contentChange" />
    <relationAdd :id="edit.id" :edit="edit" :visible.sync="visible.relation" @refresh="getDetail" />
  </div>
</template>

<script>
import chooseContent from '@/components/chooseContent'
import { pointService, relationService } from '@/service'
import { typeName } from '@/utils/variable'

import relationAdd from '../relation/Add.vue'

const service = new pointService()
const rService = new relationService()

export default {
  name: 'stepRelation',
  filters: {
    typeName(val) {
      return typeName[val]
    },
  },
  components: {
    chooseContent,
    relationAdd,
  },
  data() {
    return {
      contentData: [],
      content_sort_type: 0,
      visible: {
        choose: false,
        sort: false,
        relation: false,
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      edit: {
        id: null,
      },
      relationData: [],
      info: {},
      show: false,
      showRelation: false,
    }
  },
  created() {
    this.getInfo()
    // this.edit.id = this.$route.params.id
  },
  methods: {
    onShow() {
      this.show = !this.show
    },
    onShowRelation() {
      this.showRelation = !this.showRelation
    },
    async getInfo() {
      try {
        const { data } = await service.pointInfo({
          step: 'relation',
          id: this.$route.params.id,
        })
        this.info = data
        this.contentData = data.content_list
        this.relationData = data.poc_relation
        this.content_sort_type = data.content_sort_type
      } catch (e) {}
    },
    desc(item) {
      return `类型:${typeName[item.content_type]} 分组:${item.group_name} 作者:${item.author.real_name || ''} `
    },
    async getInfoRelation() {
      try {
        const { data } = await service.pointInfo({
          step: 'relation',
          id: this.$route.params.id,
        })
        this.relationData = data.poc_relation
      } catch (e) {}
    },
    onAddContent() {
      this.visible.choose = true
    },
    onAddSort() {
      this.visible.sort = true
    },
    contentChange(data) {
      this.contentData = data
    },
    onDeleteContent(index) {
      this.contentData.splice(index, 1)
    },
    cancel() {
      this.visible.sort = false
    },
    onAddRelation() {
      this.visible.relation = true
      this.edit.left_poc_id = this.info.id
      this.edit.left_poc_name = this.info.name
      this.edit.id = ''
    },
    onEditRelation(item) {
      this.visible.relation = true
      this.edit = item
    },
    async onDeleteRelation(item, index) {
      this.relationData.splice(index, 1)
      try {
        await rService.delete({
          id: item.id,
        })
        this.$message.success('删除成功')
        this.getDetail()
      } catch (e) {}
    },
    getDetail() {
      this.getInfoRelation()
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'content',
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.stitle {
  color: #000;
  font-size: 14px;
}

.relation {
  .content {
    min-height: 300px;
    padding: 20px;
    background: #fff;
  }

  .chart {
    min-height: 300px;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px;
    background: #fff;
  }

  .title {
    margin-bottom: 20px;
    color: #000;
    font-size: 16px;
  }

  .stitle {
    color: #000;
    font-size: 14px;
  }

  .content-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1000px;
    margin-top: 10px;

    .content-item {
      display: flex;
      width: 490px;
      height: 70px;
      margin-bottom: 20px;
      border: 1px solid #eee;

      .img {
        width: 60px;
        height: 60px;
        margin: 5px 10px 5px 5px;
        object-fit: cover;
      }

      .text {
        flex: 1;
        padding-top: 10px;

        .ttitle {
          margin-bottom: 6px;
        }
      }

      .content-btn {
        width: 66px;
        line-height: 70px;
        text-align: center;

        i {
          margin-right: 10px;
          font-size: 18px;
        }

        i:nth-child(2) {
          margin-right: 20px;
        }
      }
    }
  }

  .relation-box {
    width: 1020px;
    margin: 10px 0 0 0;

    .box {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 490px;
      height: 116px;
      padding: 10px;
      border: 1px solid #eee;

      .left {
        display: flex;
        width: 50%;
        height: 60px;

        .text {
          width: 120px;
          padding-right: 10px;

          p {
            font-size: 12px;
            text-align: right;
          }

          p:nth-child(1) {
            margin-top: 7px;
            margin-bottom: 6px;
          }

          .ttitle {
            color: #000;
            font-size: 14px;
          }
        }

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        height: 60px;

        .text {
          width: 120px;
          padding-left: 10px;

          p {
            font-size: 12px;
          }

          p:nth-child(1) {
            margin-top: 7px;
            margin-bottom: 6px;
          }

          .ttitle {
            color: #000;
            font-size: 14px;
          }
        }

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
      }

      .name {
        margin: 10px auto 0;
        padding: 0 36px;
        color: #000;
        font-size: 12px;
        border-radius: 10px;
        box-shadow: 0 1px 0 0 #eee;

        i {
          font-size: 18px;
          vertical-align: -4px;
        }
      }

      .btn {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 18px;

        i {
          margin-left: 10px;
        }
      }
    }
  }

  .blank {
    margin-right: 5px;
    color: #000;
  }

  .question {
    color: #5c5c5c;
    font-size: 20px;
  }
}

.more {
  width: 100%;
  font-size: 12px;
  line-height: 25px;

  &-left {
    color: #5c5c5c;
  }

  &-right {
    float: right;
    color: @primary-color;
    cursor: pointer;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      font-size: 17px;
      vertical-align: -3px;
    }
  }
}
</style>
