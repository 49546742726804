<template>
  <div :class="$style.relesase">
    <div :class="$style.box">
      <div :class="$style.cover">
        <img :src="tip.cover" />
        <img :class="$style.icon" :src="oss + '/manage/icon/default/done.png'" />
      </div>
      <div :class="$style.state" @click="onPreview()">
        <i class="ri-eye-line" />
      </div>
      <p :class="$style.title">
        <span v-if="!tip.status" :class="$style.status" />
        {{ tip.name }}
      </p>
      <p v-if="!tip.status" :class="$style.dec">文化点已创建成功，请确认无误后进行发布！</p>
      <p v-else :class="$style.dec">文化点已发布成功，手机扫码即可预览！</p>
      <div :class="$style.btn">
        <BjButton :class="$style.edit" @click="to()">
          <i class="ri-article-line left" />
          编辑主页
        </BjButton>
        <a-popconfirm
          v-if="!tip.status"
          title="确定立即发布文化点吗？立即发布之后前端将对所有用户显示。"
          @confirm="sumbit"
        >
          <BjButton type="primary">
            <i class="ri-send-plane-2-line left" />
            立即发布
          </BjButton>
        </a-popconfirm>
        <BjButton v-else @click="sumbit()">
          <i class="ri-arrow-down-line left" />
          立即下架
        </BjButton>
      </div>
    </div>
  </div>
</template>

<script>
import { pointService } from '@/service'

const service = new pointService()

export default {
  name: 'stepRelease',
  data() {
    return {
      tip: {
        qr_code: null,
        name: '',
        status: 0,
      },
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const { data } = await service.pointInfo({
        step: 'release',
        id: this.$route.params.id,
      })
      this.tip = data
    },
    async sumbit() {
      await service.pointSave({
        id: this.$route.params.id,
        step: 'release',
      })
      this.$message.success(this.tip.status ? '下架成功' : '发布成功')
      this.getDetail()
    },
    onPreview() {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'poc',
          id: this.tip.id,
        },
      })
    },
    to() {
      this.$store.commit('EDITOR', {
        page_type: 'poc',
        poc_id: this.$route.params.id,
      })
      this.$router.push({
        name: 'editor',
      })
    },
  },
}
</script>

<style lang="less" module>
.relesase {
  position: relative;
  width: 100%;
  height: 600px;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  .edit {
    margin-right: 30px;
  }

  .title {
    margin: 10px 0;
    color: #000;
    font-size: 20px;

    .status {
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: #bbb;
      display: inline-block;
      margin-bottom: 5px;
      margin-right: 6px;
    }
  }

  .dec {
    margin-bottom: 30px;
    color: #5c5c5c;
    font-size: 12px;
  }
  .cover {
    position: relative;

    img {
      width: 150px;
      height: 150px;
      border-radius: 6px;
      object-fit: cover;
    }

    .icon {
      position: absolute;
      top: 50px;
      left: 125px;
      width: 50px;
      height: 50px;
    }
  }

  .state {
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
  }
}
</style>
