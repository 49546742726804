<template>
  <div :class="$style.stepInit">
    <div :class="$style.header">
      <div :class="$style.icon">
        <i class="ri-emotion-line" />
      </div>
      <div :class="$style.right">
        <p :class="$style.title">文化点的新建</p>
        <p :class="$style.dec">
          文化点的新建需要四个步骤，当完成第一个步骤后，文化点即初始化完成（但处于未发布状态）。如未走完整个流程，可后期继续完善。
        </p>
      </div>
    </div>
    <div :class="$style.container">
      <p :class="$style.title">
        文化点名称
        <a-popover placement="bottomLeft" title="说明">
          <template slot="content">
            <p class="tip">
              文化点命名需遵循
              <span class="primary">运城文旅云文化点命名和类型规范</span>
              ，规范的文化点名称应该是一个专有名词，使用正式的全称或最常用的名称。如果一个文化点名称拥有两个或更多的称呼（如“四川大学”和“川大”），
              建议你使用全称（四川大学）来创建文化点，简称（川大）作为同义词同属这个文化点。
            </p>
          </template>
          <i class="ri-question-line" />
        </a-popover>
      </p>
      <div :class="$style.box">
        <a-tooltip v-if="error">
          <template slot="title">{{ error }}</template>
          <BjInput
            v-model="params.name"
            :class="[$style.boxInput, error && params.name ? $style.errorInput : $style.defaultInput]"
            placeholder="请输入文化点名称"
            @blur="checkName"
          >
            <div slot="suffix">
              <!-- <span v-for="(item, index) in synonymShow" :key="index" class="overflow" :class="$style.tag">{{
                item
              }}</span> -->
              <a-popover v-if="synonymShow.length" title="同义词">
                <template slot="content">
                  <p v-for="(item, index) in synonymShow" :key="index">{{ item }}</p>
                </template>
                <i :class="$style.tag" class="ri-input-method-line" />
              </a-popover>
            </div>
          </BjInput>
        </a-tooltip>
        <BjInput
          v-else
          v-model="params.name"
          placeholder="请输入文化点名称"
          :class="[$style.boxInput, !error && params.name ? $style.successInput : $style.defaultInput]"
          @blur="checkName"
        >
          <div slot="suffix">
            <!-- <span v-for="(item, index) in synonymShow" :key="index" class="overflow" :class="$style.tag">{{
              item
            }}</span> -->
            <a-popover v-if="synonymShow.length" title="同义词">
              <template slot="content">
                <p v-for="(item, index) in synonymShow" :key="index">{{ item }}</p>
              </template>
              <i :class="$style.tag" class="ri-input-method-line" />
            </a-popover>
          </div>
        </BjInput>
        <div :class="$style.boxBtn" @click="onSimilar()">
          <a-tooltip v-if="!synonymShow.length">
            <template slot="title"> 添加同义词 </template>
            <i class="ri-add-line" />
          </a-tooltip>
          <a-tooltip v-else>
            <template slot="title"> 修改同义词 </template>
            <i class="ri-menu-2-line" />
          </a-tooltip>
        </div>
      </div>
      <p :class="$style.title">
        文化点类型
        <a-popover placement="bottomLeft" title="说明">
          <template slot="content">
            <p class="tip">
              文化点类型需遵循 <span class="primary">运城文旅云文化点命名和类型规范</span>
              ，请按照实际所属文化类型选择，文化点类型的选择决定文化点的属性信息和文化点主页的模板配置。
            </p>
          </template>
          <i class="ri-question-line" />
        </a-popover>
      </p>
      <div :class="$style.type">
        <!-- <BjInput v-model="typeShow" placeholder="请选择文化点类型" read-only @click="onType()">
          <div slot="suffix" @click="onType()">
            <i :class="$style.icon" class="ri-arrow-down-s-line" />
          </div>
        </BjInput> -->
        <bj-cascader
          v-model="params.poc_type_id"
          change-on-select
          :class="$style.searchItem"
          :options="typeData"
          :field-names="{
            label: 'name',
            value: 'id',
            children: 'children',
          }"
          :inner-search="false"
          expand-trigger="hover"
          placeholder="请选择类型"
          :allow-clear="false"
          :load-data="onLoadData"
          @change="typeChange"
          @blur="typeBlur"
        />
        <div v-if="detailData.catalogs && detailData.attributes" :class="$style.detail">
          <div :class="$style.catalogue">
            <p :class="$style.title">文化点目录（示例）</p>
            <div>
              <div v-for="detailItem in detailData.catalogs" :key="detailItem.id" :class="$style.catalogueItem">
                · <span :class="$style.name">{{ detailItem.name }}</span>
              </div>
            </div>
          </div>
          <div :class="$style.attributes">
            <p :class="$style.title">文化点属性（示例）</p>
            <div :class="$style.attributesItem">
              <div v-for="detailItem in detailData.attributes" :key="detailItem.id">
                <span :class="$style.name">
                  {{ detailItem.name }}
                </span>
                {{ detailItem.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bj-modal
      title="文化点同义词"
      :body-style="{ 'min-height': '300px', 'max-height': '520px', 'overflow-y': 'auto' }"
      :visible="visible.similar"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <BjCard :title="tips" />
      <div v-for="(item, index) in params.synonym" :key="index" :class="$style.similar">
        <BjInput v-model="params.synonym[index]" placeholder="请输入文化点同义词" :class="$style.similarInput" />
        <div :class="$style.similarBtn">
          <i class="ri-delete-bin-7-line" @click="onDeleteSynonym(index)" />
        </div>
      </div>
      <div :class="$style.line" />
      <BjButton :class="$style.similarAdd" block @click="onAddSynonym()"
        ><i class="ri-add-line left" />添加一条</BjButton
      >
    </bj-modal>
    <!-- <bj-modal
      title="选择文化点类型"
      :visible="visible.type"
      :width="800"
      :class="$style.modal"
      :body-style="{ height: '500px', 'max-height': '600px', 'overflow-y': 'auto' }"
      @ok="typeOk"
      @cancel="typeCancel"
    >
      <a-tabs v-model="typeId" @change="onChange">
        <a-tab-pane v-for="item in typeData" :key="item.id" :tab="item.name">
          <table :class="$style.typeTable" border="1" cellspacing="0" cellpadding="0">
            <tr v-for="(childIt, childIndex) in childData" :key="childIndex">
              <td
                v-for="childItem in childIt"
                :key="childItem.id"
                :class="params.poc_type_id === childItem.id ? $style.active : null"
                @click="onChoose(childItem)"
              >
                <div v-if="params.poc_type_id === childItem.id" :class="$style.activeBtn">
                  <i class="ri-check-line" />
                </div>
                <div class="ellipsis-width">
                  {{ childItem.name }}
                </div>
              </td>
            </tr>
          </table>
        </a-tab-pane>
      </a-tabs>
    </bj-modal> -->
  </div>
</template>

<script>
import _ from 'lodash'

import { pointService } from '@/service'

const service = new pointService()

export default {
  name: 'stepInit',
  data() {
    return {
      visible: {
        type: false,
        similar: false,
      },
      params: {
        step: 'init',
        name: null,
        poc_type_id: [],
        synonym: [],
      },
      synonym: [],
      synonymShow: [],
      tips: '文化点同义词请保持与主文化点名称一致意思的名词，用户可能会通过搜索同义词来搜索此文化点。',
      typeData: [], // 一级数据
      detailData: [],
      error: '',
    }
  },
  computed: {
    edit() {
      return this.$route.params.id !== 'add'
    },
  },
  created() {
    if (this.$route.params.id === 'add') {
      this.getType()
      this.params = {
        step: 'init',
        name: null,
        poc_type_id: [],
        synonym: [],
      }
    } else {
      this.getDetail()
    }
  },
  methods: {
    async getType() {
      const { data } = await service.getType({
        parent_id: '',
        is_simple: 1,
      })
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.typeData = data
    },
    typeChange(value, selectedOptions) {
      this.detailData = selectedOptions[value.length - 1]
    },
    typeBlur() {
      if (this.params.poc_type_id.length === 1) {
        this.$message.error('文化点类型仅支持二级及以上的条目，请重新选择。')
        this.params.poc_type_id = []
      }
    },
    async onLoadData(e) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.getType({
        parent_id: targetOption.id,
        is_simple: 0,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      this.typeData = [...this.typeData]
      // this.typeData = this.typeData.concat(data)
    },
    async autoLoadData(e, index) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.getType({
        parent_id: targetOption.id,
        is_simple: 0,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      // this.typeData = this.typeData.concat(data)
      this.typeData = [...this.typeData]
      if (this.params.poc_type_id_list.length - 1 > index) {
        data.map(item => {
          if (item.id === this.params.poc_type_id_list[index]) {
            this.autoLoadData([item], index + 1)
          }
        })
      }
    },
    async autoGetTpye() {
      const { data } = await service.getType({
        parent_id: '',
        is_simple: 1,
      })
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.typeData = data
      this.typeData.map(item => {
        if (item.id === this.params.poc_type_id_list[0]) {
          this.autoLoadData([item], 1) // 获取下级 && 层级传进去 用第几层的id
        }
      })
    },
    async getDetail() {
      const { data } = await service.pointInfo({
        step: 'init',
        id: this.$route.params.id,
      })
      this.params = {
        step: 'init',
        name: data.name,
        poc_type_id: data.poc_type_id_list,
        synonym: _.cloneDeep(data.synonym),
        poc_type_id_list: data.poc_type_id_list,
      }
      this.synonymShow = _.cloneDeep(data.synonym)
      this.synonym = _.cloneDeep(data.synonym)
      this.autoGetTpye()
    },
    onSimilar() {
      this.visible.similar = true
    },
    onDeleteSynonym(index) {
      this.params.synonym.splice(index, 1)
    },
    onAddSynonym() {
      this.params.synonym.push(null)
    },
    handleOk() {
      for (var i = 0; i < this.params.synonym.length; i++) {
        if (this.params.synonym[i] == '' || this.params.synonym[i] == null) {
          this.params.synonym.splice(i, 1)
          i = i - 1
        }
      }
      this.synonym = _.cloneDeep(this.params.synonym)
      this.visible.similar = false
      this.synonymShow = []
      if (this.synonym.length > 3) {
        this.synonymShow = [this.synonym[0], this.synonym[1], this.synonym[2], '...']
      } else {
        for (let i = 0; i < this.synonym.length; i++) {
          this.synonymShow.push(this.synonym[i])
        }
      }
    },
    handleCancel() {
      this.visible.similar = false
      this.params.synonym = _.cloneDeep(this.synonym)
    },
    async checkName() {
      if (!this.params.name) return
      try {
        const { data } = await service.checkName({
          name: this.params.name,
          id: this.edit ? this.$route.params.id : '',
        })
        if (data.is_repeat) {
          this.$message.error('文化云名称重复')
          this.error = data.error
          return
        } else {
          this.error = null
        }
        if (this.params.name.length > 20) {
          this.$message.error('文化点名称最长不能超过20个字，请检查后输入。')
          this.error = '文化点名称最长不能超过20个字，请检查后输入。'
        } else {
          this.error = null
        }
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.step-init {
  :global {
    .ant-input {
      height: 60px;
      font-size: 20px;
      border: none;
      border-radius: 0;
    }

    .ant-input:focus {
      box-shadow: none;
    }

    .ant-cascader-picker {
      font-size: 20px;

      .suffix {
        top: 47%;
        right: 20px;
        font-size: 30px;
        transform-origin: 80% 50%;
      }
    }
  }

  .tag {
    color: #000;
    font-size: 24px;
  }

  .container {
    width: 600px;
    margin: 120px auto 60px;

    .title {
      color: #000;
      font-size: 16px;

      i {
        margin-left: 4px;
        color: #5c5c5c;
        font-size: 20px;
        vertical-align: -4px;
      }
    }

    .icon {
      font-size: 30px;
      cursor: pointer;
    }
  }

  .box {
    display: flex;

    &-input {
      flex: 1;
    }

    &-btn {
      width: 60px;
      height: 60px;
      margin-left: 20px;
      font-size: 30px;
      line-height: 60px;
      text-align: center;
      background: #fff;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    background: #fff;

    .icon {
      width: 50px;
      height: 50px;
      margin-left: 20px;
      text-align: center;
      background: #fafafa;
      border-radius: 25px;

      i {
        display: inline-block;
        margin-top: 10px;
        color: #000;
        font-size: 20px;
      }
    }

    .dec {
      color: #5c5c5c;
      font-size: 12px;
    }

    .right {
      flex: 1;
      padding-left: 12px;
    }

    .title {
      margin: 12px 0 4px 0;
      color: #000;
      font-size: 16px;
    }
  }
}

.similar {
  display: flex;
  height: 40px;
  margin: 5px 0 0 0;

  &-input {
    width: 425px;
  }

  &-btn {
    flex: 1;
    padding-top: 4px;
    color: @error-color;
    font-size: 20px;
    text-align: right;
  }
}

.similar-add {
  margin-top: 20px;
  color: #000;
  font-size: 12px;
  /* stylelint-disable-next-line no-descending-specificity */
  i {
    font-size: 18px;
    vertical-align: -3;
  }
}

// .type-table {
//   color: #000;
//   font-size: 12px;
//   border: 1px solid #eee;
//   user-select: none;

//   td {
//     position: relative;
//     width: 95px;
//     height: 40px;
//     text-align: center;
//     cursor: pointer;
//   }

//   td:hover {
//     background: #fafafa;
//   }

//   .active {
//     color: @primary-color;
//     background: #fafafa;

//     .active-btn {
//       position: absolute;
//       top: 0;
//       right: 0;
//       width: 0;
//       height: 0;
//       border-top: 24px solid @primary-color;
//       border-left: 24px solid transparent;

//       i {
//         position: absolute;
//         top: -23px;
//         right: 0;
//         color: #fff;
//         font-size: 12px;
//       }
//     }
//   }
// }
.detail {
  display: flex;
  min-height: 200px;
  margin: 20px 0 0;
  background-color: #fff;
  border: 1px solid #eee;

  .attributes {
    flex: 1;
    padding-top: 20px;

    .title {
      padding-left: 20px;
      color: #000;
      font-size: 14px;
    }

    &-item {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        margin-bottom: 10px;
        padding-left: 20px;
      }
    }
  }

  .catalogue {
    width: 180px;
    padding: 20px;
    border-right: 1px solid #eee;

    .title {
      margin-bottom: 14px;
      color: #000;
      font-size: 14px;
    }

    &-item {
      margin-bottom: 10px;
    }
  }
}

.success-input {
  :global {
    .ant-input-affix-wrapper {
      border-bottom: 1px solid @primary-color;
    }
  }
}

.error-input {
  :global {
    .ant-input-affix-wrapper {
      border-bottom: 1px solid @error-color;
    }
  }
}

.default-input {
  :global {
    .ant-input-affix-wrapper {
      border-bottom: 1px solid #eee;
    }
  }
}

.line {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #eee;
}

.name {
  margin-right: 10px;
  color: #5c5c5c;
}

.modal {
  :global {
    .ant-tabs-bar {
      border: none;
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.type {
  cursor: pointer;

  :global {
    .ant-input-affix-wrapper {
      border-bottom: 1px solid #eee;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    .ant-input {
      cursor: pointer;
    }
  }
}
</style>
