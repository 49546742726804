<template>
  <div :class="$style.base">
    <div :class="$style.info">
      <p :class="$style.title">基础信息</p>
      <a-row :class="[$style.infoItem, $style.layout]">
        <div :class="$style.layoutLabel"><span :class="$style.require">*</span> 封面图册</div>
        <div :class="$style.layoutValue">
          <BjButton :disabled="file.length > 9" class="btn-default mt-4" @click="openChoose()">
            <i :class="$style.align" class="ri-upload-line left" />上传封面
          </BjButton>
          <draggable v-model="file" handle=".mover">
            <div v-for="(item, index) in file" :key="item.id" :class="$style.box">
              <div :class="$style.file">
                <div :class="$style.img"><img :src="item.cover" /></div>
                <div :class="$style.name">{{ item.filename }}{{ item.ext }}</div>
                <div :class="$style.btn">
                  <i
                    v-if="item.type === 'image'"
                    :class="$style.show"
                    class="ri-eye-line primary"
                    @click="onPreview(item)"
                  />
                  <i
                    v-if="item.type === 'video'"
                    :class="$style.show"
                    class="ri-play-circle-line"
                    @click="onPreview(item)"
                  />
                  <i :class="$style.delete" class="ri-delete-bin-7-line" @click="onFileDelete(index)" />
                </div>
              </div>
              <div class="mover" :class="$style.drag">
                <i class="ri-menu-line" />
              </div>
            </div>
          </draggable>
          <p :class="$style.tips">
            文化点封面图册可以上传图片和视频，且必须上传至少一张图片用于文化点封面的主图。图片建议尺寸大于300px*300px；
            大小不超过2M；格式支持JPG、PNG；视频大小不超过 500M，格式支持MP4、WMV，最多选择10张封面。
          </p>
        </div>
      </a-row>
      <a-row :class="[$style.infoItem, $style.layout]">
        <div :class="$style.layoutLabel"><span :class="$style.require">*</span> 选择位置</div>
        <div :class="[$style.layoutValue, $style.maplocation]">
          <BjInput v-model="params.location" placeholder="请选择文化点位置">
            <div slot="suffix">
              <a-popover title="位置信息">
                <template slot="content">
                  <p>经度：{{ params.longitude }}</p>
                  <p>纬度：{{ params.latitude }}</p>
                  <p>地址：{{ params.real_location }}</p>
                </template>
                <i v-if="params.real_location" class="ri-question-line location-icon" />
              </a-popover>
              <span :class="$style.location" @click="onMapSelect('map')"><i class="ri-map-pin-2-line" />选择位置</span>
            </div>
          </BjInput>
          <p :class="$style.tip">
            请在地图上标注文化点的位置，尽量准确。如果某些文化点的位置不好确立，建议以这个文化点重大的事件，深远的影响，发扬光大的地方作为位置。
          </p>
        </div>
      </a-row>

      <a-row :class="[$style.infoItem, $style.layout]">
        <div :class="$style.layoutLabel"><span :class="$style.require">*</span> 所属分组</div>
        <div :class="$style.layoutValue">
          <BjSelect
            v-model="params.group_id"
            key-field="id"
            inner-search
            value-field="id"
            label-field="name"
            placeholder="请选择文化点所属分组"
            show-all
            :options="groupData"
          />
          <p :class="$style.tip">
            请选择文化点所属的分组，分组是由文化主自己确立，需要管理文化点分组请在左边菜单“
            <span class="primary cursor" @click="toGroup()">文化点分组</span> ”中进行管理。
          </p>
        </div>
      </a-row>
      <a-row :class="[$style.infoItem, $style.layout]">
        <div :class="$style.layoutLabel">文化标签</div>
        <div :class="$style.layoutValue">
          <div :class="$style.tagBox">
            <div :class="$style.tagItem">
              <span v-for="(item, index) in params.tags" :key="index" :class="$style.tagText">
                <span :class="$style.text" class="ellipsis-width">{{ item }}</span>
                <i class="ri-close-line" @click="deleteTag(index)" />
              </span>
              <p v-if="!params.tags.length">请添加文化标签</p>
            </div>
            <div :class="$style.tagInput">
              <span v-if="tagShow" :class="$style.location" type="primary" @click="tagShow = false">
                <i class="ri-add-line" />添加标签
              </span>
              <BjInput v-else v-model="tag" allow-clear class="nomargin" @pressEnter="tagChange" />
            </div>
          </div>
          <p :class="$style.tips">
            输入标签内容后点击回车键即可添加标签。
            <!-- ，也可以添加下方文化点词条后，从系统提供的标签中选择。 -->
          </p>
        </div>
      </a-row>

      <a-row :class="[$style.infoItem, $style.layout]">
        <div :class="$style.layoutLabel">允许评论</div>
        <div :class="$style.layoutValue">
          <bj-switch v-model="params.comment_status" size="default" />
          <p :class="$style.tip">
            评论默认审核机制为“智能审核”，如需修改，请在“
            <span class="primary">评论管理</span> ”里面设置审核机制。
          </p>
        </div>
      </a-row>
    </div>
    <div :class="$style.word">
      <p :class="$style.title">文化点词条</p>
      <p :class="$style.sTitle"><span :class="$style.require">*</span> 文化点概述</p>
      <div :class="$style.textarea">
        <a-textarea
          v-model="params.summary"
          :auto-size="{ minRows: 6, maxRows: 8 }"
          placeholder="请在这里描述文化的概述信息…"
          :max-length="500"
        />
        <span>{{ summaryLength }} / 500</span>
      </div>
      <div :class="$style.line" />
      <div>
        <a-row>
          <a-col span="12">
            <p :class="$style.sTitle">文化点属性</p>
          </a-col>
          <a-col span="12" class="text-right">
            <a-dropdown :trigger="['click']" :get-popup-container="trigger => trigger.parentNode">
              <span class="cursor" :class="$style.drop">添加属性<i class="ri-arrow-down-s-line" /></span>
              <a-menu slot="overlay" class="list-drop">
                <a-menu-item @click="onAddAttr('text')">
                  <i class="ri-text" />
                  文本类型
                </a-menu-item>
                <a-menu-item @click="onAddAttr('date')">
                  <i class="ri-calendar-line" />
                  时间类型
                </a-menu-item>
                <a-menu-item @click="onAddAttr('location')">
                  <i class="ri-map-pin-2-line" />
                  位置类型
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-col>
        </a-row>
        <a-row :gutter="[20, 20]" :class="$style.attr">
          <draggable v-model="params.attributes">
            <a-col v-for="(item, index) in params.attributes" :key="index" span="8">
              <a-row :class="$style.flex">
                <a-col span="8" :class="$style.name">
                  <i class="ri-menu-line" />
                  <a-input v-model="item.name" />
                </a-col>
                <a-col span="16">
                  <div v-for="(it, itIndex) in item.value" :key="itIndex" :class="$style.attrBox">
                    <BjInput v-if="item.type === 'text'" v-model="it.text" />
                    <a-date-picker v-if="item.type === 'date'" v-model="it.date" />
                    <div v-if="item.type === 'location'">
                      <BjInput v-model="it.location">
                        <div slot="suffix">
                          <span @click="onMapSelect('attr', { index: index, itIndex: itIndex })">
                            <i :class="$style.size18" class="ri-map-pin-2-line" />
                          </span>
                        </div>
                      </BjInput>
                    </div>
                    <span :class="$style.attrBtns">
                      <span :class="$style.attrBtn">
                        <i
                          v-if="!itIndex"
                          style="margin-right: 5px; color: #000"
                          class="ri-add-circle-line"
                          @click="addValue('text', index)"
                        />
                        <i
                          style="color: #ff2d55"
                          class="ri-delete-bin-7-line"
                          @click="deleteValue('text', index, itIndex)"
                        />
                      </span>
                    </span>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </draggable>
        </a-row>
      </div>
      <div :class="$style.line" />
      <a-row>
        <a-col span="12">
          <p :class="$style.sTitle"><span :class="$style.require">*</span>文化点介绍</p>
        </a-col>
        <a-col span="12" class="text-right">
          <!-- <a-dropdown :trigger="['click']" :get-popup-container="trigger => trigger.parentNode">
            <span class="cursor" :class="$style.drop">文件目录<i class="ri-menu-2-line" /> </span>
            <a-menu slot="overlay">
              <a-menu-item @click="onAddAttr('text')"> 目录 </a-menu-item>
            </a-menu>
          </a-dropdown> -->
        </a-col>
      </a-row>
      <div :class="$style.editor">
        <BjEditor ref="editor" v-model="params.content" placeholder="从这里开始写正文" />
      </div>
      <!-- <div :class="$style.line" />
      <a-row>
        <a-col span="6">参考资料</a-col>
        <a-col span="18"> todo </a-col>
      </a-row> -->
    </div>
    <!-- <div :class="$style.group">
      <p>资料信息</p>
      <a-row>
        <a-col span="8">文化点资料</a-col>
        <a-col spam="16">
          <a-tabs v-model="params" type="editable-card" @edit="onEdit">
            <a-tab-pane v-for="item in material" :key="item.key" :tab="pane.title" :closable="pane.closable">
              {{ pane.content }}
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div> -->
    <!-- <div :class="$style.chain">
      <p :class="$style.title">区块链信息</p>
      <a-row>
        <a-col span="6" :class="$style.sTitle">是否上链</a-col>
        <a-col span="18">
          <a-switch v-model="params.is_chain" />
          <p>请选择是否将此文化点在区块链中存储，存储后会返回该文化点的哈希值。</p>
          <p>此功能属于旗舰版功能，你当前为【标准版】，你可以“查看服务详情”以购买此服务。</p>
        </a-col>
      </a-row>
    </div> -->
    <bj-modal
      title="选择位置"
      :visible="visible.map"
      :class="$style.modal"
      :width="800"
      :force-render="true"
      :body-style="{ height: '500px', 'max-height': '600px', 'overflow-y': 'auto' }"
      @ok="mapOk"
      @cancel="mapCancel"
    >
      <div id="container" />
      <div :class="$style.search">
        <input id="tipinput" autocomplete="off" placeholder="请输入位置关键词" type="text" />
        <i class="ri-search-2-line" />
      </div>
    </bj-modal>
    <chooseMaterial
      :visible.sync="visible.choose"
      :multiple="true"
      :allow="['image', 'video']"
      @getChoose="getChoose"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import BjEditor from '@/components/BjEditor'
import chooseMaterial from '@/components/chooseMaterial.vue'
import { groupService, pointService } from '@/service'

const AMap = window.AMap
const gService = new groupService()
const service = new pointService()

export default {
  name: 'stepBase',
  components: {
    draggable,
    chooseMaterial,
    BjEditor,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: {
        map: false,
        choose: false,
        editor: false,
      },
      map: null,
      file: [],
      params: {
        id: null,
        cover_atlas: [],
        location: '',
        real_location: '',
        longitude: '',
        latitude: '',
        group_id: [],
        tags: [],
        summary: null,
        content: null,
        attributes: [],
        is_chain: false,
        comment_status: 1,
      },
      placeSearch: null,
      marker: null,
      groupData: [],
      tagShow: true,
      tag: null,
      mapType: 'map', // map 文坏点位置 attr 类型位置
      mapAttr: {
        index: 0,
        itIndex: 0,
      },
      center: [],
      // 编辑器
      material: [],
    }
  },
  computed: {
    summaryLength() {
      return this.params.summary ? this.params.summary.length : 0
    },
  },
  created() {
    this.getGroup()
    this.getInfo()
    this.getDefault()
  },
  methods: {
    onPreview(item) {
      let tmp = this.file.map(item => ({
        cover: item.path,
        type: item.type,
      }))
      this.BjPreview(tmp, item.path)
    },
    onAddAttr(type) {
      if (type === 'text') {
        this.params.attributes.push({
          name: '文本类型',
          type: 'text',
          value: [{ text: null }],
        })
      }
      if (type === 'date') {
        this.params.attributes.push({
          name: '日期类型',
          type: 'date',
          value: [{ date: null }],
        })
      }
      if (type === 'location') {
        this.params.attributes.push({
          name: '位置类型',
          type: 'location',
          value: [{ location: null, longitude: null, latitude: null }],
        })
      }
    },
    addValue(type, index) {
      if (type === 'text') {
        this.params.attributes[index].value.push({ text: null })
      }
    },
    deleteValue(type, index, itIndex) {
      if (type === 'text') {
        if (itIndex) {
          this.params.attributes[index].value.splice(itIndex, 1)
        } else {
          this.params.attributes.splice(index, 1)
        }
      }
    },
    onFileDelete(index) {
      this.file.splice(index, 1)
    },
    getChoose(file) {
      if (this.file.length + file.length > 10) {
        this.file = this.file.concat(file).splice(0, 10)
        this.$message.error('文化点封面最多设置10条封面内容，超出部分会被截取！')
      } else {
        this.file = this.file.concat(file)
      }
    },
    tagChange() {
      this.tagShow = true
      if (this.tag) {
        this.params.tags.push(this.tag)
      }
      this.tag = null
    },
    deleteTag(index) {
      this.params.tags.splice(index, 1)
    },
    async getGroup() {
      try {
        const { data } = await gService.list()
        this.groupData = data
      } catch (e) {}
    },
    toGroup() {
      this.$router.push({
        name: 'resourcesGroup',
      })
    },
    openChoose() {
      if (this.file.length === 10) {
        this.$message.error('文化点封面最多设置10条封面内容，超出部分会被截取！')
        return
      }
      this.visible.choose = true
    },
    onMapSelect(type, index) {
      this.mapType = type
      if (index) {
        this.mapAttr = index
      }
      this.visible.map = true
      this.$nextTick(() => {
        if (!this.center.length) {
          this.center = [104.065681, 30.653442]
        }
        this.map = new AMap.Map('container', {
          zoom: 8, //级别
          center: this.center, //中心点坐标
          viewMode: '3D', //使用3D视图
        })
        var auto = new AMap.Autocomplete({
          input: 'tipinput',
        })
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(this.center[0], this.center[1]),
          title: '位置',
          draggable: true,
        })
        this.placeSearch = new AMap.PlaceSearch({
          map: this.map,
        }) //构造地点查询类
        this.map.add(this.marker)
        AMap.event.addListener(auto, 'select', this.select)
      })
    },
    select(e) {
      if (!e.poi.location) {
        this.$message.error('请选择具体位置！')
        return
      }
      this.map.setZoomAndCenter(15, [e.poi.location.lng, e.poi.location.lat])
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(e.poi.location.lng, e.poi.location.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '位置',
        draggable: true,
      })
      this.map.remove(this.marker)
      this.map.add(this.marker)
    },
    mapOk() {
      let lnglat = this.marker.getPosition()
      let geocoder = new AMap.Geocoder({
        city: '全国',
      })
      if (this.mapType === 'map') {
        geocoder.getAddress(lnglat, (status, { regeocode }) => {
          if (status === 'complete') {
            this.params.location = regeocode.formattedAddress
            this.params.real_location = regeocode.formattedAddress
            this.params.adcode = regeocode.addressComponent.adcode
          }
        })
        this.params.latitude = lnglat.lat
        this.params.longitude = lnglat.lng
      }
      if (this.mapType === 'attr') {
        geocoder.getAddress(lnglat, (status, { regeocode }) => {
          if (status === 'complete') {
            const tmp = {
              latitude: lnglat.lat,
              longitude: lnglat.lng,
              location: regeocode.formattedAddress,
            }
            this.params.attributes[this.mapAttr.index].value.splice(this.mapAttr.itIndex, 1, tmp)
          }
        })
      }
      this.visible.map = false
    },
    mapCancel() {
      document.getElementById('tipinput').value = null
      this.visible.map = false
    },
    async getInfo() {
      const { data } = await service.pointInfo({
        step: 'base',
        id: this.$route.params.id,
      })
      this.params.attributes = data.attributes
      this.params.content = data.content
      this.params.group_id = data.group_id || []
      this.params.is_chain = data.is_chain === 1 ? true : false
      this.params.location = data.location
      this.params.real_location = data.real_location
      this.params.latitude = data.latitude
      this.params.longitude = data.longitude
      this.params.material = data.material
      this.params.summary = data.summary
      this.params.tags = data.tags
      this.file = data.cover_atlas
      this.material = data.material
      if (this.data.id) {
        this.data.attributes.map(item => {
          if (item.type === 'text') {
            item.value = [{ text: null }]
          }
          if (item.type === 'date') {
            item.value = [{ date: null }]
          }
          if (item.type === 'location') {
            item.value = [{ location: null, longitude: null, latitude: null }]
          }
        })
        this.params.attributes = this.data.attributes
      }
    },
    getDefault() {
      let citysearch = new AMap.CitySearch()
      //自动获取用户IP，返回当前城市
      citysearch.getLocalCity((status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.city && result.bounds) {
            this.center = result.rectangle.split(';')[0].split(',')
          }
        }
      })
    },
    // eslint-disable-next-line vue/no-unused-properties
    getParams() {
      let material = []
      this.material.map(item => {
        if (this.params.content.indexOf(item.id) !== -1) {
          material.push(item)
        }
      })
      return {
        ...this.params,
        is_chain: this.params.is_chain ? 1 : 0,
        cover_atlas: this.file,
        id: this.$route.params.id,
        group_id: Array.isArray(this.params.group_id) ? '' : this.params.group_id,
        material: material,
      }
    },
  },
}
</script>

<style lang="less" module>
.base {
  padding-bottom: 70px;

  :global {
    .ant-form-item {
      margin: 0;
    }

    .ant-col-6 {
      color: #000;
      font-size: 14px;
      line-height: 40px;
    }
  }

  .info {
    padding: 20px;
    background: #fff;

    &-item {
      width: 740px;
      margin-top: 20px;

      .location {
        display: inline-block;
        width: 84px;
        height: 28px;
        color: #fff;
        font-size: 12px;
        line-height: 28px;
        text-align: center;
        background: @primary-color;
        border-radius: 6px;
        cursor: pointer;

        i {
          font-size: 16px;
          vertical-align: -3px;
        }
      }

      :global {
        .ant-input {
          height: 38px;
          border-radius: 0;
        }

        .ant-select-selection {
          height: 38px;
          border-radius: 0;
        }

        .ant-select-selection__rendered {
          line-height: 38px;
        }
      }
    }

    .file {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      width: 562px;
      height: 70px;
      border: 1px solid #eee;

      .img {
        width: 60px;
        height: 60px;
        margin: 0 5px;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
      }

      .name {
        display: flex;
        flex: 1;
        align-items: center;
        padding-left: 20px;
        color: #000;
        font-size: 12px;
      }

      .btn {
        width: 65px;
        padding: 20px 20px 0 0;
        text-align: right;

        .delete {
          color: @error-color;
          font-size: 18px;
        }

        .show {
          margin-right: 10px;
          color: @primary-color;
          font-size: 18px;
        }
      }
    }
  }

  .box {
    display: flex;
    margin-top: 10px;

    .drag {
      flex: 1;
      padding-left: 20px;
      line-height: 68px;

      i {
        color: #000;
        font-size: 18px;
      }
    }
  }

  .title {
    margin-bottom: 20px;
    color: #000;
    font-size: 16px;
  }

  .s-title {
    color: #000;
    font-size: 14px;
  }
}

.modal {
  /* stylelint-disable-next-line no-descending-specificity */
  :global {
    .ant-modal-body {
      position: relative;
    }
  }

  .search {
    position: absolute;
    top: 30px;
    right: 30px;

    input {
      width: 200px;
      height: 36px;
      padding-left: 10px;
      background: #fff;
      border: none;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #000;
      font-size: 18px;
    }
  }
}

.tips {
  margin-top: 10px;
  color: #5c5c5c;
  font-size: 12px;
}

.tag-box {
  display: flex;
  padding: 5px;
  border: 1px solid #eee;

  .tag-item {
    flex: 1;

    p {
      margin: 0;
      margin-left: 6px;
      color: #bbb;
      line-height: 26px;
    }

    .tag-text {
      position: relative;
      display: inline-block;
      width: 66px;
      height: 24px;
      margin: 2px 10px 0 0;
      color: #5c5c5c;
      font-size: 10px;
      text-align: center;
      background: #eee;
      border-radius: 4px;

      .text {
        margin-top: 3px;
        padding: 0 10px 0 3px;
      }

      i {
        position: absolute;
        top: 2px;
        right: 3px;
        font-size: 14px;
      }
    }
  }

  .tag-input {
    :global {
      .ant-input {
        width: 84px;
        height: 28px;
        border-radius: 6px;
      }
    }
  }
}

.word {
  margin-top: 20px;
  padding: 15px;
  background: #fff;

  .textarea {
    position: relative;

    span {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    textarea {
      border: none;
    }
  }

  .line {
    width: 100%;
    margin: 20px 0;
    border-top: 1px solid #eee;
  }
}

.attr {
  margin-top: 20px;
  /* stylelint-disable-next-line no-descending-specificity */
  :global {
    .ant-calendar-picker {
      width: 100%;
    }

    .ant-form-item-control {
      line-height: 38px;
    }
  }

  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  /* stylelint-disable-next-line no-descending-specificity */
  .name {
    height: 38px;
    color: #000;
    line-height: 38px;

    :global {
      .ant-input {
        width: calc(100% - 40px);
        height: 40px;
        border: none;
        border-radius: 0;
      }
    }
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      margin-right: 10px;
      font-size: 18px;
      vertical-align: -3px;
    }
  }

  &-box {
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 30px;
    border: 1px solid #eee;
    /* stylelint-disable-next-line no-descending-specificity */
    :global {
      .ant-calendar-picker {
        width: 100%;
        height: 38px;
      }
      /* stylelint-disable-next-line no-descending-specificity */
      .ant-input {
        height: 38px;
        border: none;
      }

      .ant-input:hover {
        border: none;
      }

      .ant-input:focus {
        border: none;
        box-shadow: none;
      }
    }
  }

  &-btns {
    position: absolute;
    top: -27px;
    right: 0;
    display: none;
    height: 34px;
  }

  &-box:hover {
    .attr-btns {
      display: inline-block;
    }
  }

  &-btn {
    display: inline-block;
    max-width: 46px;
    height: 24px;
    padding: 0 6px;
    line-height: 24px;
    border: 1px solid #eee;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      font-size: 14px;
    }
  }
}

.chain {
  margin-top: 20px;
  padding: 15px;
  background: #fff;
}

.require {
  color: @error-color;
}

.align {
  vertical-align: -2;
}

.drop {
  color: #000;
  /* stylelint-disable-next-line no-descending-specificity */
  i {
    margin-left: 2px;
    font-size: 19px;
    vertical-align: -4px;
  }
}

.tip {
  margin: 10px 0 10px 0;
  color: #5c5c5c;
  font-size: 12px;
}

.layout {
  display: flex;

  &-label {
    width: 140px;
    height: 40px;
    line-height: 40px;
  }

  &-value {
    width: 600px;
  }
}

.size18 {
  font-size: 18px;
}

.maplocation {
  :global {
    .ant-input {
      padding-right: 125px !important;
    }
  }

  i {
    vertical-align: -6px;
  }
}

.editor {
  .loading {
    text-align: center;
    height: 454px;
    line-height: 454px;
  }

  :global {
    .tox-tinymce {
      border: none;
    }

    .tox .tox-toolbar,
    .tox .tox-toolbar__overflow,
    .tox .tox-toolbar__primary {
      background: none;
    }

    .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
      position: relative;
      border: none;
    }

    .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type):before {
      content: '';
      position: absolute;
      right: 0;
      top: 10.5px;
      height: 18px;
      width: 1px;
      background-color: #eee;
    }

    .tox .tox-tbtn--enabled {
      background: #ddd;
    }

    .tox .tox-tbtn:hover {
      background: #eee;
    }

    .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
      width: auto;
    }
  }
}
</style>

<style lang="less">
#container {
  width: 752px;
  height: 450px;
}
</style>
