<template>
  <bj-modal
    :visible="visible"
    :width="850"
    :class="$style.add"
    :title="title"
    :body-style="{
      height: '600px',
      position: 'relative',
      'overflow-y': 'auto',
    }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <p :class="$style.titleFir">
      <span class="required">*</span>
      文化点关系
    </p>
    <a-row type="flex" :gutter="20" justify="space-between">
      <a-col span="8">
        <div :class="$style.relation">
          <a-select
            v-model="params.left_poc_id"
            :disabled="true"
            class="mb-6"
            show-search
            placeholder="搜索主文化点"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="loading ? undefined : null"
            @search="leftHandleSearch"
          >
            <a-spin v-if="loading" slot="notFoundContent" size="small" />
            <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
            <a-select-option v-for="item in leftData" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <BjInput v-model="params.left_poc_desc" allow-clear placeholder="关联描述，显示在文化点下面" />
        </div>
      </a-col>
      <a-col span="8">
        <div :class="$style.relation">
          <!-- <bj-cascader
            v-model="params.poc_relation_id"
            class="mb-6"
            :options="tree"
            placeholder="请选择关系类型"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
          /> -->
          <bj-cascader
            v-model="params.poc_relation_id"
            class="mb-6"
            :options="tree"
            change-on-select
            expand-trigger="hover"
            :field-names="{
              label: 'name',
              value: 'id',
              children: 'children',
            }"
            :inner-search="false"
            placeholder="请选择关系类型"
            :allow-clear="false"
            :load-data="onLoadData"
          />
          <BjSelect v-model="params.poc_relation_way" show-all placeholder="请选择关系方向">
            <a-select-option :value="0"> 单向<i class="ri-arrow-right-fill" /> </a-select-option>
            <a-select-option :value="1"> 单向<i class="ri-arrow-left-fill" /> </a-select-option>
            <a-select-option :value="2"> 双向<i class="ri-arrow-left-right-line" /> </a-select-option>
          </BjSelect>
        </div>
      </a-col>
      <a-col span="8">
        <div :class="$style.relation">
          <a-select
            v-model="params.right_poc_id"
            class="mb-6"
            :disabled="!!edit.id"
            show-search
            placeholder="搜索关联文化点"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="loading ? undefined : null"
            @search="rightHandleSearch"
          >
            <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
            <a-spin v-if="loading" slot="notFoundContent" size="small" />
            <a-select-option v-for="item in rightData" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <BjInput v-model="params.right_poc_desc" allow-clear placeholder="关联描述，显示在文化点下面" />
        </div>
      </a-col>
    </a-row>
    <p :class="$style.title">关系时间</p>
    <BjInput v-model="params.relation_time_desc" placeholder="请这段关系发生的时间进行描述" />
    <a-radio-group v-model="params.relation_time_type" :class="$style.radio" :options="timeOption" />
    <div v-if="params.relation_time_type !== 0" :class="$style.time">
      <span :class="$style.trans">起</span>
      <div :class="$style.ad">
        <BjSelect v-model="params.relation_time.time_type">
          <a-select-option :value="1"> 公元后 </a-select-option>
          <a-select-option :value="0"> 公元前 </a-select-option>
        </BjSelect>
      </div>
      <div :class="$style.date">
        <a-date-picker v-model="params.relation_time.date" />
      </div>
      <div :class="$style.day">
        <a-time-picker v-model="params.relation_time.time" format="HH:mm" />
      </div>
      <div :class="$style.accuracy">
        <BjSelect
          v-model="params.relation_time.accuracy"
          v-bind="layout"
          key-field="id"
          label="准确度"
          value-field="id"
          show-all
          label-field="label"
          :options="accuracyData"
        />
      </div>
    </div>
    <div v-if="params.relation_time_type === 2" :class="$style.time">
      <span :class="$style.trans">终</span>
      <div :class="$style.ad">
        <BjSelect v-model="relation_time.time_type">
          <a-select-option :value="1"> 公元后 </a-select-option>
          <a-select-option :value="0"> 公元前 </a-select-option>
        </BjSelect>
      </div>
      <div :class="$style.date">
        <a-date-picker v-model="relation_time.date" />
      </div>
      <div :class="$style.day">
        <a-time-picker v-model="relation_time.time" format="HH:mm" />
      </div>
      <div :class="$style.accuracy">
        <BjSelect
          v-model="relation_time.accuracy"
          v-bind="layout"
          key-field="id"
          label="准确度"
          show-all
          value-field="id"
          label-field="label"
          :options="accuracyData"
        />
      </div>
    </div>
    <p :class="$style.title" class="mb-10">关系位置</p>
    <div>
      <BjInput v-model="params.location" class="cursor map" placeholder="请选择这段关系发生的位置">
        <div slot="suffix">
          <a-popover title="位置信息">
            <template slot="content">
              <p>经度：{{ params.longitude }}</p>
              <p>纬度：{{ params.latitude }}</p>
              <p>地址：{{ params.real_location }}</p>
            </template>
            <i v-if="params.real_location" style="vertical-align: -6px" class="ri-question-line location-icon" />
          </a-popover>
          <span :class="$style.location" @click="onMapSelect()"
            ><i class="ri-map-pin-line mr-5" />
            <span>选择位置</span>
          </span>
        </div>
      </BjInput>
    </div>
    <div>
      <p :class="$style.title">关系描述</p>
      <div :class="$style.textarea">
        <a-textarea
          v-model="params.relation_desc"
          :auto-size="{ minRows: 4, maxRows: 4 }"
          placeholder="请简要描述这段关系"
          :max-length="120"
        />
        <span>{{ params.relation_desc ? params.relation_desc.length : 0 }} / 120</span>
      </div>
    </div>
    <BjMap :visible.sync="mapVisible" @change="mapChange" />
  </bj-modal>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import BjMap from '@/components/BjMap.vue'
import { relationService } from '@/service'
import { timeOption } from '@/utils/variable.js'

const service = new relationService()

export default {
  name: 'Add',
  components: {
    BjMap,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    edit: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mapVisible: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      params: {
        left_poc_id: undefined,
        right_poc_id: undefined,
        poc_relation_id: [],
        left_poc_desc: null,
        right_poc_desc: null,
        relation_time_desc: null,
        relation_time_type: 0,
        relation_time: {
          type: 0,
          time_type: 0,
          date: null,
          time: null,
          accuracy: 0,
        },
        real_location: null,
        location: null,
        longitude: null,
        latitude: null,
        relation_desc: null,
      },
      relation_time: {
        type: 0,
        time_type: 0,
        date: null,
        time: null,
        accuracy: 0,
      },
      leftData: [],
      rightData: [],
      loading: false,
      tree: [],
      timeOption,
      accuracyData: [
        {
          label: '准确',
          id: 0,
        },
        {
          label: '大约',
          id: 1,
        },
        {
          label: '存疑',
          id: 2,
        },
      ],
    }
  },
  computed: {
    title() {
      return this.id ? '编辑图谱关系' : '添加图谱关系'
    },
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.edit.id) {
          this.getDetail()
          this.params = _.cloneDeep(this.edit)
          this.leftData = [
            {
              name: this.edit.left_poc_name,
              id: this.edit.left_poc_id,
            },
          ]
          this.rightData = [
            {
              name: this.edit.right_poc_name,
              id: this.edit.right_poc_id,
            },
          ]
          this.params.relation_time = {
            type: 0,
            time_type: 0,
            date: null,
            time: null,
            accuracy: 0,
          }
          if (this.edit.relation_time_type === 1) {
            this.params.relation_time = this.edit.relation_time[0]
            this.params.relation_time.time = moment(this.params.relation_time.time, 'HH:mm')
            this.params.relation_time.date = moment(this.params.relation_time.date, 'YYYY-MM-DD')
          }
          if (this.edit.relation_time_type === 2) {
            this.relation_time = this.edit.relation_time[1]
            this.relation_time.time = moment(this.relation_time.time, 'HH:mm')
            this.relation_time.date = moment(this.relation_time.date, 'YYYY-MM-DD')
            this.params.relation_time = this.edit.relation_time[0]
            this.params.relation_time.time = moment(this.params.relation_time.time, 'HH:mm')
            this.params.relation_time.date = moment(this.params.relation_time.date, 'YYYY-MM-DD')
          }
        } else {
          this.getTree()
          this.init()
          if (this.edit.left_poc_id) {
            this.leftData = [
              {
                name: this.edit.left_poc_name,
                id: this.edit.left_poc_id,
              },
            ]
            this.params.left_poc_id = this.edit.left_poc_id
          }
        }
      }
    },
  },
  methods: {
    getDetail() {
      this.autoGetTpye()
    },
    async autoGetTpye() {
      const { data } = await service.tree({
        parent_id: '',
      })
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.tree = data
      this.tree.map(item => {
        if (item.id === this.params.poc_relation_id[0]) {
          this.autoLoadData([item], 1) // 获取下级 && 层级传进去 用第几层的id
        }
      })
    },
    async autoLoadData(e, index) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.tree({
        parent_id: targetOption.id,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      this.$forceUpdate()
      if (this.params.poc_relation_id.length - 1 > index) {
        data.map(item => {
          if (item.id === this.params.poc_relation_id[index]) {
            this.autoLoadData([item], index + 1)
          }
        })
      }
    },
    async getTree() {
      const { data } = await service.tree()
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.tree = data
    },
    async onLoadData(e) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.tree({
        parent_id: targetOption.id,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      this.tree = [...this.tree]
    },
    async leftHandleSearch(value) {
      try {
        this.loading = true
        const { data } = await service.list({
          name: value,
        })
        this.leftData = data.record
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async rightHandleSearch(value) {
      try {
        this.loading = true
        const { data } = await service.list({
          name: value,
        })
        this.rightData = data.record
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async handleOk() {
      try {
        let param = null
        if (this.params.relation_time_type === 0) {
          param = {
            ...this.params,
            poc_relation_id: this.params.poc_relation_id[this.params.poc_relation_id.length - 1],
          }
        }
        if (this.params.relation_time_type === 1) {
          let time = {
            time: this.params.relation_time.time.format('HH:mm'),
            date: this.params.relation_time.date.format('YYYY-MM-DD'),
          }
          param = {
            ...this.params,
            poc_relation_id: this.params.poc_relation_id[this.params.poc_relation_id.length - 1],
            relation_time: JSON.stringify([{ ...this.params.relation_time, ...time }]),
          }
        }
        if (this.params.relation_time_type === 2) {
          let time = [
            {
              time: this.params.relation_time.time ? this.params.relation_time.time.format('HH:mm') : '',
              date: this.params.relation_time.date ? this.params.relation_time.date.format('YYYY-MM-DD') : '',
              type: 0,
            },
            {
              time: this.relation_time.time ? this.relation_time.time.format('HH:mm') : '',
              date: this.relation_time.date ? this.relation_time.date.format('YYYY-MM-DD') : '',
              type: 1,
            },
          ]
          param = {
            ...this.params,
            poc_relation_id: this.params.poc_relation_id[this.params.poc_relation_id.length - 1],
            relation_time: JSON.stringify([
              { ...this.params.relation_time, ...time[0] },
              { ...this.relation_time, ...time[1] },
            ]),
          }
          if (this.params.relation_time_type === 0) {
            param.relation_time = ''
          }
          if (this.params.relation_time_type === 1) {
            param.relation_time = JSON.stringify([{ ...this.params.relation_time, ...time[0] }])
          }
          if (this.params.relation_time_type === 2) {
            param.relation_time = JSON.stringify([
              { ...this.params.relation_time, ...time[0] },
              { ...this.relation_time, ...time[1] },
            ])
          }
          if (this.id) {
            param.id = this.id
          }
        }
        this.id ? await service.edit(param) : await service.add(param)
        this.$message.success(this.id ? '编辑成功' : '添加成功')
        this.init()
        this.handleCancel()
        this.$emit('refresh')
      } catch (e) {}
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    onMapSelect() {
      this.mapVisible = true
    },
    mapChange(map) {
      this.params.location = map.location
      this.params.real_location = map.location
      this.params.longitude = map.longitude
      this.params.latitude = map.latitude
      this.params.adcode = map.adcode
      this.$forceUpdate()
    },
    init() {
      this.params = {
        left_poc_id: undefined,
        right_poc_id: undefined,
        poc_relation_id: [],
        left_poc_desc: null,
        right_poc_desc: null,
        relation_time_desc: null,
        relation_time_type: 0,
        relation_time: {
          type: 0,
          time_type: 0,
          date: null,
          time: null,
          accuracy: 0,
        },
        location: null,
        real_location: null,
        longitude: null,
        latitude: null,
        adcode: null,
        relation_desc: null,
      }
      this.relation_time = {
        type: 0,
        time_type: 0,
        date: null,
        time: null,
        accuracy: 0,
      }
    },
  },
}
</script>

<style lang="less" module>
.add {
  :global {
    .ant-select {
      width: 100%;
    }

    .ant-col-8 {
      padding: 0 10px;
    }

    .ant-cascader-picker {
      width: 100%;
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-time-picker {
      width: 100%;
    }

    .ant-calendar-picker {
      width: 100%;
    }
  }

  .relation {
    padding: 10px 10px 6px 10px;
    background: #fafafa;

    :global {
      .ant-select-arrow {
        top: 40%;
      }
    }
  }

  .time {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .ad {
      width: 90px;
      margin: 0 5px;
    }

    .day {
      width: 200px;
      margin: 0 5px;
    }

    .date {
      width: 200px;
      margin: 0 5px;
    }

    .accuracy {
      flex: 1;
    }
  }

  .location {
    display: inline-block;
    width: 84px;
    height: 28px;
    color: #fff;
    font-size: 12px;
    line-height: 28px !important;
    text-align: center;
    background: @primary-color;
    border-radius: 6px;
    cursor: pointer;

    i {
      font-size: 16px;
      vertical-align: -3px;
    }
  }

  .title {
    margin: 20px 0 14px;
    color: #000;
    font-size: 14px;
  }

  .title-fir {
    margin: 0 0 14px;
    color: #000;
    font-size: 14px;
  }

  .radio {
    margin-top: 10px;
  }

  .textarea {
    position: relative;

    span {
      position: relative;
      top: -24px;
      left: -22px;
      float: right;
    }
  }
}

.trans {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: #5c5c5c;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 4px;
}
</style>
