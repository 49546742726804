<template>
  <div :class="$style.add">
    <div :class="$style.container">
      <stepInit v-if="step === 0" ref="init" />
      <stepBase v-if="step === 1" ref="base" :data="stepInitData" />
      <stepRelation v-if="step === 2" ref="relation" />
      <stepRelease v-if="step === 3" ref="release" />
    </div>
    <div :class="$style.footer" :style="{ width: footWidth, left: left }">
      <div v-if="step === 0" :class="$style.btns">
        <BjButton class="btn-default" @click="initBack()"><i class="ri-delete-back-line left" />取消创建</BjButton>
        <BjButton type="primary" @click="initSubmit()"><i class="ri-arrow-right-line left" />下一步</BjButton>
      </div>
      <div v-if="step === 1" :class="$style.btns">
        <BjButton class="btn-default" @click="back(0)"><i class="ri-arrow-left-line left" />上一步</BjButton>
        <BjButton type="primary" @click="baseSubmit()"><i class="ri-arrow-right-line left" />下一步</BjButton>
      </div>
      <div v-if="step === 2" :class="$style.btns">
        <BjButton class="btn-default" @click="back(1)"><i class="ri-arrow-left-line left" />上一步</BjButton>
        <BjButton type="primary" @click="relationSubmit()"><i class="ri-arrow-right-line left" />下一步</BjButton>
      </div>
      <div v-if="step === 3" :class="$style.btns">
        <BjButton class="btn-default" @click="back(2)"><i class="ri-arrow-left-line left" />上一步</BjButton>
        <BjButton type="primary" @click="backHome()"><i class="ri-arrow-go-back-line left" />返回列表</BjButton>
      </div>
      <div :class="$style.steps">
        <a-steps :class="$style.step" size="small" :current="step">
          <a-step title="初始创建" />
          <a-step title="基本信息" />
          <a-step title="关联信息" />
          <a-step title="预览发布" />
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 操作步骤
 * init - 初始步骤
 * base - 基本信息
 * relation - 关联信息
 * release - 预览发布
 */
// import { stepState } from '@/utils/variable'

import { pointService } from '@/service'

import stepBase from './stepBase.vue'
import stepInit from './stepInit.vue'
import stepRelation from './stepRelation.vue'
import stepRelease from './stepRelease.vue'

const service = new pointService()

export default {
  name: 'Add',
  components: {
    stepInit,
    stepBase,
    stepRelation,
    stepRelease,
  },
  data() {
    return {
      step: 0,
      stepInitData: {},
      // stepState,
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(val) {
        this.$store.commit('COLLAPSED', val)
      },
    },
    sideChirdShow: {
      get() {
        return this.$store.state.sideChirdShow
      },
      set(val) {
        this.$store.commit('SIDECHIRDSHOW', val)
      },
    },
    footWidth() {
      let left = this.collapsed ? (this.sideChirdShow ? '260px' : '100px') : this.sideChirdShow ? '340px' : '180px'
      let w = 'calc(100% - ' + left + ')'
      return w
    },
    left() {
      let left = this.collapsed ? (this.sideChirdShow ? '240px' : '80px') : this.sideChirdShow ? '320px' : '160px'
      return left
    },
  },
  methods: {
    // init
    initBack() {
      this.$router.push({
        name: 'resourcesPoint',
      })
    },
    back(step) {
      this.step = step
    },
    backHome() {
      this.$router.push({
        name: 'resourcesPoint',
      })
    },
    async initSubmit() {
      const params = this.$refs.init.params
      if (this.$refs.init.error) {
        return
      }
      if (!params.name) {
        this.$message.error('请输入文化点名称')
        return
      }
      if (!params.poc_type_id) {
        this.$message.error('请选择文化点类型')
        return
      }
      let edit = this.$route.params.id !== 'add'
      try {
        const { data } = await service.pointSave({
          step: 'init',
          id: edit ? this.$route.params.id : '',
          ...params,
          poc_type_id: params.poc_type_id[params.poc_type_id.length - 1],
        })
        this.$message.success(edit ? '修改成功' : '创建成功')
        if (!edit) {
          this.stepInitData = data
          this.$router.replace({
            name: 'resourcesPointAdd',
            params: { id: data.id },
          })
        }
        this.step = 1
      } catch (e) {}
    },
    async baseSubmit() {
      const param = this.$refs.base.getParams()
      try {
        await service.pointSave({
          step: 'base',
          info: JSON.stringify(param),
        })
        this.$message.success('保存成功')
        this.step = 2
      } catch (e) {}
    },
    async relationSubmit() {
      try {
        let param = {
          content_ids: [],
          content_sort_type: this.$refs.relation.content_sort_type,
        }
        this.$refs.relation.contentData.map(item => {
          param.content_ids.push(item.id)
        })
        await service.pointSave({
          step: 'relation',
          id: this.$route.params.id,
          ...param,
        })
        this.$message.success('保存成功')
        this.step = 3
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.add {
  .footer {
    position: fixed;
    bottom: 0;
    left: 15px;
    z-index: 99;
    display: flex;
    align-items: center;
    // width: calc(100% - 30px);
    height: 60px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);

    .btns {
      width: 280px;

      button {
        margin: 0 10px;
      }

      i {
        font-size: 18px;
        vertical-align: -4px;
      }
    }

    .steps {
      flex: 1;

      .step {
        width: 500px;
        margin-left: auto;
      }

      :global {
        .ant-steps-item-title {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
