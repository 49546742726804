<template>
  <bj-modal
    :class="$style.point"
    :visible="visible"
    :width="800"
    title="选择内容"
    :body-style="{ height: '500px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
    @cancel="cancel"
  >
    <div :class="$style.search">
      <BjInput
        v-model="search.keyword"
        :class="$style.searchItem"
        allow-clear
        placeholder="请输入内容关键词"
        @pressEnter="onSearch()"
      />
      <BjSelect
        v-model="search.content_type"
        :class="$style.searchItem"
        key-field="value"
        placeholder="请选择内容形式"
        value-field="value"
        label-field="label"
        show-all
        :options="typeArr"
      />
      <BjSelect
        v-model="search.group_id"
        :class="$style.searchItem"
        key-field="id"
        inner-search
        placeholder="请选择分组"
        value-field="id"
        label-field="name"
        show-all
        :options="groupData"
      />
      <BjButton class="btn-default" @click="onSearch()">搜索</BjButton>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="false" row-key="id">
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox
            v-if="multiple"
            :checked="selectedRowKeys.indexOf(item.id) !== -1"
            @change="onChange(item.id, item)"
          />
          <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
        </div>
      </template>
      <template #cover="item">
        <div :class="$style.coverBox">
          <div :class="$style.cover">
            <img :src="item.cover.cover" />
          </div>
          <div :class="$style.name">
            <a-tooltip placement="top">
              <template slot="title">
                {{ item.title }}
              </template>
              <p class="ellipsis-width2">{{ item.title }}</p>
            </a-tooltip>
          </div>
        </div>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>
    <template #footer>
      <a-row>
        <a-col :class="$style.left" span="12">
          <span :class="$style.select">{{ selectedRowKeys.length }}</span
          >条内容已被选择
        </a-col>
        <a-col class="text-right" span="12">
          <BjButton @click="cancel()">取消</BjButton>
          <BjButton type="primary" @click="getChoose()">确定</BjButton>
        </a-col>
      </a-row>
    </template>
  </bj-modal>
</template>

<script>
import { contentGroupService, manageService } from '@/service'
import { typeArr } from '@/utils/variable.js'

const service = new manageService()
const gService = new contentGroupService()

export default {
  name: 'chooseContent',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: {
        keyword: null,
        group_id: '',
        content_type: '',
      },
      page: 1,
      pageSize: 20,
      total: 0,
      data: [],
      selectedRowKeys: [],
      groupData: [],
      typeData: [],
      selectData: [],
      typeArr,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        {
          title: '内容名称',
          width: 200,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '内容类型',
          dataIndex: 'content_type',
          customRender: value => {
            switch (value) {
              case 'post':
                return '图文'
              case 'video':
                return '视频'
              case 'audio':
                return '音频'
              case 'image':
                return '图册'
            }
          },
        },
        { title: '所属分组', dataIndex: 'group_name' },
        { title: '作者', customRender: item => item.author.real_name || '-' },
        {
          title: '发布时间',
          dataIndex: 'publish_time',
        },
      ]
    },
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.select && this.select.length) {
          this.select.map(({ id }) => {
            this.selectedRowKeys.push(id)
          })
        } else {
          this.selectedRowKeys = []
        }
        this.getList()
      } else {
        this.init()
      }
    },
  },
  created() {
    this.getGroup()
    this.getType()
  },
  methods: {
    async getList() {
      const { data } = await service.list({
        ...this.search,
        page: this.page,
        page_size: this.pageSize,
        is_choose: 1, // 选择列表默认加这个
      })
      this.data = data.record
      this.total = data.total
    },
    async getType() {
      const { data } = await service.typeList({})
      data.map(item => {
        item.disabled = true
      })
      this.typeData = data
    },
    async getGroup() {
      try {
        const { data } = await gService.list({
          no_page: 1,
        })
        this.groupData = [
          {
            id: '',
            name: '全部分组',
          },
        ].concat(data)
      } catch (e) {}
    },
    getChoose() {
      if (this.selectedRowKeys.length) {
        let tmp = []
        this.selectedRowKeys.map(id => {
          for (let index = 0; index < this.selectData.concat(this.select).length; index++) {
            if (id === this.selectData.concat(this.select)[index].id) {
              tmp.push(this.selectData.concat(this.select)[index])
              break
            }
          }
        })
        this.$emit('change', tmp)
      }
      this.$emit('update:visible', false)
    },
    cancel() {
      this.$emit('update:visible', false)
    },
    onChange(id, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(id) === -1) {
          this.selectedRowKeys.push(id)
          this.selectData.push(item)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(id)
        this.selectData = []
        this.selectData.push(item)
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    init() {
      this.search = {
        keyword: null,
        group_id: '',
        content_type: '',
      }
      this.selectedRowKeys = []
    },
  },
}
</script>

<style lang="less" module>
.point {
  .search {
    display: flex;

    :global {
      .ant-form-item-control {
        line-height: 0;
      }
    }

    .search-item {
      width: 200px;
      margin-right: 10px;
    }
  }

  .select {
    position: relative;
    top: -2px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background: @primary-color;
    border-radius: 10px;
  }

  .cover-box {
    display: flex;
    align-items: center;
    width: 250px;
    padding: 8px;
  }

  .cover {
    position: relative;
    width: 70px;
    height: 44px;
    overflow: hidden;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name {
    flex: 1;
    padding-left: 10px;
    color: #5c5c5c;
    font-size: 13px;

    p {
      margin: 0;
    }
  }

  .left {
    height: 32px;
    line-height: 32px;
    text-align: left;
  }
}
</style>
